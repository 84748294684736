import React from "react";
import {FileUploader} from "react-drag-drop-files";
import {Box, Stack, Typography} from "@mui/material";
import {Check} from "@mui/icons-material";

export const Uploader = ({handleChange, file, uploaded}) => {
    return (
        <Box sx={{width: {xs: '100%', md: 'min(25rem, 100%)'}, py: 2}}>
            {uploaded ? <Box sx={{border: '2px dashed #2CA8FF', borderRadius: '1rem', cursor: 'pointer', py: 5}}>
                    <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={2}>
                        <Check sx={{color: '#2CA8FF', fontSize: 32}}/>
                        <Typography textAlign={'center'} variant={'h6'}>
                            File caricato
                        </Typography>
                    </Stack>
                </Box>
                :
                <FileUploader
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    types={["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "pdf", "PDF"]}
                    label={'Carica o trascina il file qui'}
                    hoverTitle={"Rilascia qui"}
                    children={
                        <Stack alignItems={'center'}
                               sx={{border: '2px dashed #2CA8FF', borderRadius: '1rem', cursor: 'pointer', py: 5}}>
                            {file === null ?
                                <Typography textAlign={'center'}>
                                    Nessun file caricato,
                                    <br/>
                                    <u>clicca</u> o trascina il file qui
                                </Typography>
                                :
                                <Typography textAlign={'center'}>
                                    {`File caricato: ${file.name}`}
                                    <br/>
                                    <u>clicca</u> o trascina il file qui per cambiare file
                                </Typography>
                            }
                        </Stack>
                    }
                />
            }
        </Box>
    )
}

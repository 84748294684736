import React, {useEffect, useMemo, useState} from "react";
import {Box, Button, Container, Stack, TextField, Typography, CircularProgress, Chip} from "@mui/material";
import HomeLayout from "../layouts/HomeLayout";
import {ArrowBackIos} from "@mui/icons-material";
import {Link, useParams} from "react-router-dom";
import {useAthletesStore} from "../store/athletesStore";
import {FileUploader} from "react-drag-drop-files";
import {API_URL} from "../config";
import {post} from "axios";
import {Uploader} from "../components/Upload/Uploader";
import {DateField, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AlertBar} from "../components/AlertBar";
import {green} from "@mui/material/colors";
import {Title} from "../components/Title";
import {getAuthToken} from "../functions/getAuthToken";
import dayjs from "dayjs";

export const Upload = () => {
    const {id} = useParams()
    const {athletes} = useAthletesStore()
    const [athlete, setAthlete] = React.useState({})
    const [certificato, setCertificato] = useState(null)
    const [fototessera, setFototessera] = useState(null)
    const [scadenza, setScadenza] = useState(null)
    const [dati, setDati] = useState({
        certificato:false,
        fototessera:false,
        privacy:false
    })
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [paying, setPaying] = useState(false)

    useEffect(()=> {
        if (athletes.length > 0) {
            let a = athletes.find(a => a.id === id)
            athletes.find(a => a.id === id) && setAthlete(a)
        }
    },[athletes])

    useEffect(() => {
        if (athlete) {
            setDati({
                certificato: (dayjs(athlete.scadenzaCertificato)?.isAfter(dayjs())) ? athlete.certificato : null,
                fototessera:athlete.fototessera
            })
            if (athlete.scadenzaCertificato) {
                setScadenza(dayjs(athlete.scadenzaCertificato)?.isAfter(dayjs()) ? dayjs(athlete.scadenzaCertificato) : null)
            }
        }
    },[athlete])

    const previuosSeason = useMemo(() => {
        return parseInt(athlete?.stagione) <= 2023
    }, [athlete])

    const handleChangeCertificato = (file) => {
        setCertificato(file);
    };

    const handleChangeFototessera = (file) => {
        setFototessera(file);
    };

    const sendFiles = async (e) => {
        e.preventDefault()
        setError(false)
        setSuccess(false)
        setSending(true)
        const formData = new FormData();

        formData.append('user_id', id);

        if (fototessera !== null) {
            formData.append('fototessera', fototessera)
        }

        if (certificato !== null && !!scadenza && scadenza.isAfter(dayjs())) {
            formData.append('certificato', certificato)
            formData.append('scadenzaCertificato',scadenza)
        }

        const token = await getAuthToken()
        await post(`${API_URL}documents`, formData, {headers: {'Authorization': `${token}`}})
            .then(() => {
                setSuccess(true)
                setTimeout(() => {
                    window.location.reload()
                }, 300)
            })
            .catch(() => setError(true))
        setSending(false)
    }

    const retryPayment = async (e) => {
        e.preventDefault()
        setPaying(true)
        const token = await getAuthToken()
        await post(`${API_URL}retry-payment/${id}`, {}, {headers: {'Authorization': `${token}`}})
            .then(res => window.location.href = res.data.url)
            .catch(() => setError(true))
        setPaying(false)
    }

    return(
        <HomeLayout page={"Completa l'iscrizione"}>
            <AlertBar open={success} setOpened={setSuccess} severity={'success'} message={'Documenti caricati con successo'}/>
            <AlertBar open={error} setOpened={setError} severity={'error'} message={'Si è verificato un errore, si prega di riprovare'}/>
            <Container sx={{py: 2}} component={'form'} onSubmit={sendFiles}>
                <Box sx={{pb:2}}>
                    <Button startIcon={<ArrowBackIos/>} variant={'outlined'} component={Link} to={'/'}>
                        Indietro
                    </Button>
                </Box>
                <Box>
                    Aggiungi i documenti per l'atleta <b>{athlete.nome} {athlete.cognome}</b>
                </Box>
                <Box sx={{py:1}}>
                    <Title title={'Pagamento'}/>
                    {athlete.pagamento ?
                        <Typography color={green[400]} fontWeight={'bold'}>
                            Quota di iscrizione pagata
                        </Typography>
                        :
                        paying ? <CircularProgress/> : <Button disabled={previuosSeason} variant={'contained'} color={'secondary'} onClick={retryPayment}>
                            Procedi al pagamento
                        </Button>
                    }
                </Box>
                <Box sx={{py:1}}>
                    <Stack direction={'row'} spacing={1} alignItems={'start'}>
                        <Title title={'Certificato medico'}/>
                        {(!!athlete.scadenzaCertificato && dayjs(athlete.scadenzaCertificato)?.isBefore(dayjs())) &&
                            <Chip label={"Certificato scaduto"} color={'error'} size={'small'}/>}
                    </Stack>
                    <Typography>
                        Se non hai il modulo di richiesta del certificato medico potrai richiederlo durante gli allenamenti
                    </Typography>
                    <Uploader uploaded={dati.certificato} file={certificato} handleChange={handleChangeCertificato}/>
                    <Box sx={{width: {xs:'100%', md:'min(25rem, 100%)'}}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                format={'DD/MM/YYYY'}
                                variant={'standard'}
                                disabled={certificato === null && (dayjs(athlete.scadenzaCertificato)?.isAfter(dayjs()))}
                                required={certificato !== null}
                                minDate={dayjs()}
                                fullWidth
                                label="Scadenza certificato"
                                value={scadenza}
                                onChange={(newValue) => setScadenza(newValue)}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Box sx={{py:2}}>
                    <Title title={'Foto tessera'}/>
                    <Uploader uploaded={dati.fototessera} file={fototessera} handleChange={handleChangeFototessera}/>
                </Box>
                <Box>
                    {sending ? <CircularProgress/>
                        : <Button variant={'contained'}
                                  color={'secondary'}
                                  type={'submit'}
                                  disabled={!fototessera && !(!!certificato && (!scadenza || (!!scadenza && scadenza?.isAfter(dayjs()))))}
                        >
                        salva
                    </Button>}
                </Box>
            </Container>
        </HomeLayout>
    )
}
